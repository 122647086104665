<template>
  <div class="add">
    <div class="title">
      <span>编辑套餐信息</span>
    </div>
    <div class="srcoll">
      <div class="srcoll-title">
        <span>套餐信息</span>
      </div>
      <div class="list">
        <div class="item">
          <span class="key">套餐名称</span>
          <el-input placeholder="请输入套餐名称"></el-input>
        </div>
        <div class="item">
          <span class="key">套餐类型</span>
          <el-select v-model="value" placeholder="选择套餐类型">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="key">所属院区</span>
          <el-select v-model="value" placeholder="选择所属院区">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="key">套餐项目</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item item1">
          <span class="key">套餐价格</span>
          <el-input placeholder="请输入套餐价格"></el-input>
        </div>
        <div class="item item1 item2">
          <span class="key">套餐价格</span>
          <el-upload class="avatar-uploader" action="" :show-file-list="false" :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <div v-else class="upload">
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <span>点击上传</span>
            </div>
          </el-upload>
        </div>
        <div class="item item1 item2">
          <span class="key">套餐介绍</span>
          <el-input type="textarea" placeholder="">
          </el-input>
        </div>
        <div class="item item1 item2">
          <span class="key">注意事项</span>
          <el-input type="textarea" placeholder="">
          </el-input>
        </div>
        <div class="item item1 item2">
          <span class="key">套餐状态</span>
          <el-switch v-model="value1" active-color="#0BBB0B" inactive-color="#D5D5D5" active-text="启动" inactive-text="禁用">
          </el-switch>
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button class="submit" size="small" @click="close">保存</el-button>
      <el-button class="close" size="small" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      value1: '',
      imageUrl: ''
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.add {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 19px;
  border: 4px solid rgba(1, 124, 219, .27);
  background-color: #092543;
  box-sizing: border-box;

  .title {
    height: 48px;
    line-height: 48px;
    padding-left: 18px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 20px;
    font-weight: bold;
  }

  .srcoll {
    width: 100%;
    height: calc(100% - 120px);
    margin-top: 10px;

    .srcoll-title {
      height: 45px;
      line-height: 45px;
      padding-left: 10px;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: start;
      width: 100%;
      height: calc(100% - 45px);
      padding: 0 20px;
      box-sizing: border-box;

      .item {
        display: flex;
        align-items: center;
        width: 25%;
        margin-bottom: 20px;
        color: #01EEFD;
        font-size: 18px;

        .key {
          margin-right: 10px;
        }

        .el-input {
          width: 200px;
        }

        /deep/.el-input__inner {
          border-radius: 0;
        }

        .avatar-uploader {
          width: 261px;
          height: 119px;
        }

        .avatar {
          width: 261px;
          height: 119px;
        }

        .upload {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 261px;
          height: 119px;
          background-color: #fff;
          font-size: 16px;
        }

        .el-textarea {
          width: 949px;
        }

        .el-switch {
          display: flex;
          justify-content: flex-start;
          margin-left: -50px;
          margin-top: 4px;
        }

        /deep/.el-textarea__inner {
          border-radius: 0;
          resize: none;
        }

        /deep/.el-switch__label--left {
          position: relative;
          left: 70px;
          color: #fff;
          z-index: -1111;

          span {
            width: 40px;
          }
        }

        /deep/.el-switch__label--right {
          position: relative;
          right: 70px;
          color: #fff;
          z-index: -1111;

          span {
            display: inline-block;
            width: 40px;
          }
        }

        /deep/.el-switch__core {
          width: 65px !important;
        }

        /deep/.el-switch__label--left.is-active {
          z-index: 10;
          color: #fff;
          white-space: nowrap;
        }

        /deep/.el-switch__label--right.is-active {
          z-index: 10;
          white-space: nowrap;
        }
      }

      .item1 {
        width: 100%;
      }

      .item2 {
        align-items: flex-start;
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    height: 50px;
  }

  .submit,
  .close {
    margin-right: 20px;
    background-color: #33465C;
    border-radius: 0;
    border: none;
    color: #FFFFFF;
    font-size: 14px;
  }

  .submit {
    background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
  }
}
</style>
