<template>
  <div class="write">
    <div class="title">
      <span>部门人员信息</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="info-title">
          <div class="info-box">
            <div class="left">
              <el-input class="el-input-width" placeholder="请输入部门"></el-input>
              <el-button size="small" class="blue">搜索</el-button>
            </div>
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" height="200" :header-cell-style="{ background: '#024276' }">
            <el-table-column label="人员名称"></el-table-column>
            <el-table-column label="人员头像"></el-table-column>
            <el-table-column label="人员性别"></el-table-column>
            <el-table-column label="人员类型"></el-table-column>
            <el-table-column label="职务等级"></el-table-column>
            <el-table-column label="联系方式"></el-table-column>
            <el-table-column label="操作" width="140">
              <!-- slot-scope="scope" -->
              <template>
                <div class="flex flex1">
                  <span>移除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
      <div class="btns">
        <el-button>取消</el-button>
        <el-button class="button">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: ''
    }
  },
  methods: {
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 3.75rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 800px;
    height: 7.5rem;
    margin-top: .125rem;
    padding: .625rem .8125rem;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: .275rem;
      right: .3rem;
      color: #fff;
      font-size: .3rem;
      cursor: pointer;
    }

    .content-title {
      margin-top: .25rem;
      color: #01EEFD;
      font-size: .225rem;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      .info-title {
        width: 100%;
        height: .625rem;
        color: #01EEFD;
        font-size: .25rem;
        font-weight: bold;
      }

      .info-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 .125rem;
        padding-bottom: 0;
        box-sizing: border-box;

        .left {
          display: flex;
          align-items: center;

          .search-input {
            width: 3.25rem;
          }

          .el-select,
          .el-input {
            width: 2.5rem;
            margin-right: .125rem;
          }

          .date {
            margin-right: .125rem;
          }

          .blue,
          .gray {
            margin-left: .5rem;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            margin-right: .125rem;
            background-color: #284867;
          }
        }

        .right {
          width: .375rem;
          height: .375rem;
          line-height: .375rem;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: .225rem;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .table {
        width: 99%;
        margin-top: .125rem;
        border: .05rem #073F73 solid;
      }

      ::v-deep .el-table__body-wrapper {
        &::-webkit-scrollbar {
          // 整个滚动条
          width: 0rem; // 纵向滚动条的宽度
        }
      }

      ::v-deep .el-table th.gutter {
        display: none;
        width: 0;
      }

      ::v-deep .el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      ::v-deep .el-table__body {
        width: 100% !important;
      }

      /deep/.el-table__header-wrapper {
        thead {
          th {
            div {
              color: #01EEFD;
              font-size: .2125rem;
              font-weight: 400;
            }
          }
        }
      }

      /deep/.el-table__body tbody tr {
        color: #fff;
        font-size: .2rem;
        background-color: #092B50;
      }

      /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background-color: #092B50;
      }

      /deep/.el-table tbody tr:hover>td {
        background-color: transparent !important
      }

      .el-table--border,
      .el-table--group {
        border: .025rem solid #ddd !important;
      }

      .el-table--border td,
      .el-table--border th,
      .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
        border-right: .0125rem solid #ddd !important;
      }

      /deep/.el-table td {
        border-bottom: .0125rem solid #01EEFD;
      }

      /deep/.el-table thead tr th {
        border-color: transparent;
      }

      /deep/ .el-table--border::after,
      /deep/ .el-table--group::after,
      /deep/ .el-table::before {
        background-color: #073F73;
      }

      /deep/.el-table--border {
        border: .0125rem solid #073F73 !important;
      }

      /deep/.el-table .cell {
        text-align: center;
        color: #01EEFD;
      }

      /deep/.el-table th.gutter {
        display: none;
        width: 0rem;
      }

      /deep/.el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      /deep/ .el-checkbox__inner {
        background-color: transparent;
      }

      /deep/ .el-table__body-wrapper {
        background-color: #092B50;
      }

      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: .2rem;

        span {
          color: #0077F9;
          cursor: pointer;
          letter-spacing: 0;
        }

        .red {
          color: #FF0000;
        }

        //按钮文字显示
        .el-switch {
          width: .875rem !important;
          height: .3125rem !important;
        }

        ::v-deep .el-switch__core {
          width: 100% !important;
          height: .3rem;
          border-radius: 1.25rem;
          border: none;
        }

        ::v-deep .el-switch__core::after {
          width: .25rem;
          height: .25rem;
          top: .025rem;
        }

        ::v-deep .el-switch.is-checked .el-switch__core::after {
          margin-left: -0.2625rem;
        }

        /*关闭时文字位置设置*/
        ::v-deep .el-switch__label--right {
          position: absolute;
          z-index: 1;
          right: .075rem;
          margin-left: 0rem;
          color: rgba(255, 255, 255, 0.9019607843137255);

          span {
            font-size: .15rem;
          }
        }

        /* 激活时另一个文字消失 */
        ::v-deep .el-switch__label.is-active {
          display: none;
        }

        /*开启时文字位置设置*/
        ::v-deep .el-switch__label--left {
          position: absolute;
          z-index: 1;
          left: .0625rem;
          margin-right: 0rem;
          color: rgba(255, 255, 255, 0.9019607843137255);

          span {
            font-size: .15rem;
          }
        }

      }

      .flex1 {
        justify-content: center;
      }

      .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: .125rem;

        ::v-deep.el-pagination__total,
        ::v-deep.el-pagination__jump {
          color: #01EEFD;

          .el-input__inner {
            color: #fff;
            background-color: transparent;
          }
        }

        ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #01EEFD;
        }
      }
    }

    .btns {
      position: absolute;
      bottom: .7rem;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 3rem;
      height: .575rem;
      margin: 1.25rem auto 0;

      .el-button {
        height: .375rem;
        line-height: .375rem;
        padding: 0 .125rem;
        text-align: center;
        color: #fff;
        font-size: .175rem;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
