<template>
  <div class="knowledgeBase">
    <div class="tab">
      <span class="title">部门管理</span>
    </div>
    <div class="content">
      <div class="intervene">
        <div class="info-title">
          <div class="info-box">
            <div class="left">
                <el-input class="el-input-width" placeholder="请输入部门"></el-input>
                <el-button size="small" class="blue">搜索</el-button>
                <el-button size="small" class="blue">导出</el-button>
                <el-button size="small" class="blue">导入</el-button>
                <el-button size="small" class="gray">删除</el-button>
              </div>
            <div class="right" @click="openAdd">
              <span class="el-icon-plus"></span>
            </div>
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" height="340" :header-cell-style="{ background: '#024276' }">
            <el-table-column type="selection" width="42"></el-table-column>
            <el-table-column prop="name" label="编号"></el-table-column>
            <el-table-column label="部门名称"></el-table-column>
            <el-table-column label="部门编号"></el-table-column>
            <el-table-column label="部门类型"></el-table-column>
            <el-table-column label="机构联系方式"></el-table-column>
            <el-table-column label="地址"></el-table-column>
            <el-table-column label="排序值"></el-table-column>
            <el-table-column label="启动状态">
              <template slot-scope="scope">
                <div class="flex flex1">
                  <el-switch v-model="scope.row.value" active-color="#01EEFD" inactive-color="#D5D5D5" active-text="启动"
                    inactive-text="禁用">
                  </el-switch>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="170">
              <!-- slot-scope="scope" -->
              <template>
                <div class="flex">
                  <span @click="openUpdate">编辑</span>
                  <span @click="openInfo">人员信息</span>
                  <span @click="openDelete">删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 新增部门 -->
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <!-- 编辑部门 -->
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
    <!-- 删除 -->
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
    <!-- 部门人员信息 -->
    <Info v-if="showInfo" @close="closeInfo"></Info>
  </div>
</template>

<script>
import Add from './add.vue'
import Update from './update.vue'
import Delete from './delete.vue'
import Info from './info.vue'
export default {
  components: {
    Add,
    Update,
    Delete,
    Info
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }],
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      showAdd: false,
      showUpdate: false,
      showDelete: false,
      showInfo: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    openInfo () {
      this.showInfo = true
    },
    closeInfo () {
      this.showInfo = false
    }
  }
}
</script>

<style lang="less" scoped>
.knowledgeBase {
  position: relative;
  width: calc(100% - .125rem);
  height: calc(100% - .125rem);
  padding: .25rem;
  border: .05rem solid rgba(1, 124, 219, .27);
  box-sizing: border-box;
  background-color: #08223D;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: .75rem;
    border-bottom: .0125rem solid #01EEFD;
    box-sizing: border-box;

    span {
      height: .475rem;
      line-height: .475rem;
      margin-right: .2rem;
      padding: 0 .15rem;
      text-align: center;
      color: #fff;
      font-size: .275rem;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .title {
      background-color: #01EEFD;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 1rem);
    margin-top: .25rem;

    .intervene {
      position: relative;
      width: 100%;
      height: 100%;

      .info-title {
        width: 100%;
        height: .625rem;
        margin-bottom: .25rem;
        border-bottom: .0125rem solid #01EEFD;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: .25rem;
        font-weight: bold;
      }

      .info-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 .125rem;
        padding-bottom: 0;
        box-sizing: border-box;

        .left {
          display: flex;
          align-items: center;

          .search-input {
            width: 3.25rem;
          }

          .el-select,
          .el-input {
            width: 2.5rem;
            margin-right: .125rem;
          }

          .date {
            margin-right: .125rem;
          }

          .blue,
          .gray {
            margin-left: .5rem;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            margin-right: .125rem;
            background-color: #284867;
          }
        }

        .right {
          width: .375rem;
          height: .375rem;
          line-height: .375rem;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: .225rem;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .table {
        width: 99%;
        margin-top: .125rem;
        border: .05rem #073F73 solid;
      }

      ::v-deep .el-table__body-wrapper {
        &::-webkit-scrollbar {
          // 整个滚动条
          width: 0rem; // 纵向滚动条的宽度
        }
      }

      ::v-deep .el-table th.gutter {
        display: none;
        width: 0;
      }

      ::v-deep .el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      ::v-deep .el-table__body {
        width: 100% !important;
      }

      /deep/.el-table__header-wrapper {
        thead {
          th {
            div {
              color: #01EEFD;
              font-size: .2125rem;
              font-weight: 400;
            }
          }
        }
      }

      /deep/.el-table__body tbody tr {
        color: #fff;
        font-size: .2rem;
        background-color: #092B50;
      }

      /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background-color: #092B50;
      }

      /deep/.el-table tbody tr:hover>td {
        background-color: transparent !important
      }

      .el-table--border,
      .el-table--group {
        border: .025rem solid #ddd !important;
      }

      .el-table--border td,
      .el-table--border th,
      .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
        border-right: .0125rem solid #ddd !important;
      }

      /deep/.el-table td {
        border-bottom: .0125rem solid #01EEFD;
      }

      /deep/.el-table thead tr th {
        border-color: transparent;
      }

      /deep/ .el-table--border::after,
      /deep/ .el-table--group::after,
      /deep/ .el-table::before {
        background-color: #073F73;
      }

      /deep/.el-table--border {
        border: .0125rem solid #073F73 !important;
      }

      /deep/.el-table .cell {
        text-align: center;
        color: #01EEFD;
      }

      /deep/.el-table th.gutter {
        display: none;
        width: 0rem;
      }

      /deep/.el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      /deep/ .el-checkbox__inner {
        background-color: transparent;
      }

      /deep/ .el-table__body-wrapper {
        background-color: #092B50;
      }

      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: .2rem;

        span {
          color: #0077F9;
          cursor: pointer;
          letter-spacing: 0;
        }

        .red {
          color: #FF0000;
        }

        //按钮文字显示
        .el-switch {
          width: .875rem !important;
          height: .3125rem !important;
        }

        ::v-deep .el-switch__core {
          width: 100% !important;
          height: .3rem;
          border-radius: 1.25rem;
          border: none;
        }

        ::v-deep .el-switch__core::after {
          width: .25rem;
          height: .25rem;
          top: .025rem;
        }

        ::v-deep .el-switch.is-checked .el-switch__core::after {
          margin-left: -0.2625rem;
        }

        /*关闭时文字位置设置*/
        ::v-deep .el-switch__label--right {
          position: absolute;
          z-index: 1;
          right: .075rem;
          margin-left: 0rem;
          color: rgba(255, 255, 255, 0.9019607843137255);

          span {
            font-size: .15rem;
          }
        }

        /* 激活时另一个文字消失 */
        ::v-deep .el-switch__label.is-active {
          display: none;
        }

        /*开启时文字位置设置*/
        ::v-deep .el-switch__label--left {
          position: absolute;
          z-index: 1;
          left: .0625rem;
          margin-right: 0rem;
          color: rgba(255, 255, 255, 0.9019607843137255);

          span {
            font-size: .15rem;
          }
        }

      }

      .flex1 {
        justify-content: center;
      }

      .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: .125rem;

        ::v-deep.el-pagination__total,
        ::v-deep.el-pagination__jump {
          color: #01EEFD;

          .el-input__inner {
            color: #fff;
            background-color: transparent;
          }
        }

        ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #01EEFD;
        }
      }
    }
  }
}
</style>
