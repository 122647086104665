<template>
  <div class="info">
    <div class="scroll">
      <el-form>
        <div class="info-title">
          <span>员工基本信息</span>
        </div>
        <div class="info-box">
          <div class="item">
            <span class="key">员工姓名</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">身份证</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">性别</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">出生日期</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">民族</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">婚姻状态</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">籍贯</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">户籍地址</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">家庭地址</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">联系电话</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span>紧急联系人电话</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span>员工头像</span>
            <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
        <div class="info-box">
          <div class="item">
            <span class="key">文化程度</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span class="key">政治面貌</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">职称级别</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">职称</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">职务</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span class="key">专业类型</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span class="key">薪资范围</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">其他待遇</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span class="key">其他待遇</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span>合同类型</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
          <div class="item">
            <span>合同编号</span>
            <el-input placeholder="请输入关键字"></el-input>
          </div>
        </div>
        <div class="info-box">
          <div class="item">
            <span>合同开始时间</span>
            <el-date-picker type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="item">
            <span>合同结束时间</span>
            <el-date-picker type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="item">
            <span>入职时间</span>
            <el-date-picker type="date" placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="item">
            <span class="key">人员类型</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span class="key">工作岗位</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="item item1">
            <el-button>小组划分</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <div class="btns">
      <el-button class="color">提交</el-button>
      <el-button>返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imageUrl: '',
      options: [{
        value: '选项1',
        label: '黄金糕'
      }],
      value: ''
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  width: 100%;
  height: 100%;

  .info-title {
    width: 100%;
    padding-left: .225rem;
    color: #01EEFD;
    font-size: .225rem;
    font-weight: bold;
  }

  .scroll {
    width: 100%;
    height: calc(100% - .625rem);
  }

  .info-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: .25rem;
    padding-bottom: 0;
    box-sizing: border-box;
    border-bottom: 1px solid #01EEFD;

    .item {
      display: flex;
      align-items: center;
      width: 25%;
      margin-bottom: .25rem;
      color: #01EEFD;
      font-size: .225rem;

      span {
        margin-right: .125rem;
      }

      .key {
        width: 1rem;
        text-align-last: justify;
      }

      .el-input {
        width: 2.125rem;
        height: .475rem;
      }

      .el-input-width {
        width: 3.1rem;
      }

      .el-button {
        background-color: #01EEFD;
        color: #fff;
        border: none;
      }
    }

    .item1 {
      width: 100%;
    }
  }

  /deep/.avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-color: #fff;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .btns {
    display: flex;
    align-items: center;
    width: 100%;
    height: .5rem;
    padding-left: .4125rem;
    box-sizing: border-box;

    .el-button {
      width: .875rem;
      height: .325rem;
      line-height: .325rem;
      margin-right: .625rem;
      text-align: center;
      background-color: #33465C;
      color: #fff;
      font-size: .175rem;
      border: 0;
      padding: 0;
      border-radius: 0;
    }

    .color {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
