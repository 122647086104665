<template>
  <div class="tab">
    <div class="input">
      <!-- <div class="item">
        <el-input placeholder="请输入套餐名称"></el-input>
      </div>
      <el-button class="blue" size="small">搜索</el-button> -->
      <div class="add" @click="openAdd">
        <span class="el-icon-plus"></span>
      </div>
    </div>
    <div class="swich">
      <el-table :data="tableData" height="400" max-height="400" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="类型名称">
        </el-table-column>
        <el-table-column prop="date" label="套餐数量">
        </el-table-column>
        <el-table-column prop="date" label="包含套餐">
        </el-table-column>
        <el-table-column prop="type" label="包含套餐">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.type" active-color="#01EEFD" inactive-color="#D5D5D5" active-text="启动"
              inactive-text="禁用">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <!-- slot-scope="scope" -->
          <template>
            <div class="flex">
              <span @click="openAdd">编辑</span>
              <span>删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
    <Add v-if="showAdd" @close="closeAdd"></Add>
  </div>
</template>

<script>
import Add from './add.vue'
export default {
  components: {
    Add
  },
  data () {
    return {
      tabs: Object.freeze(['体检套餐', '体检项目', '套餐类型']),
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '3'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      tabIndex: 0,
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      showAdd: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;

  .input {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 78px;
    padding-bottom: 10px;
    border-bottom: 1px solid #01EEFD;

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
    }

    .blue {
      margin-right: 20px;
      background-color: #01EEFD;
      font-size: 16px;
    }

    .item {
      display: flex;
      align-items: center;
      padding: 10px 0;
      margin-right: 20px;
      color: #01EEFD;
      font-size: 18px;

      .el-radio {
        color: #fff;
      }

      /deep/.el-radio__label {
        font-size: 18px;
      }

      /deep/.el-radio__input.is-checked+.el-radio__label {
        color: #01EEFD;
      }

      /deep/.el-radio__input.is-checked .el-radio__inner {
        background-color: #01EEFD;
        border-color: #01EEFD;
      }

      .span {
        margin-right: 10px;
      }

      .el-input {
        width: 206px;
        height: 38px;
        line-height: 38px;
        background-color: #fff;

        /deep/.el-input__inner {
          height: 38px;
          line-height: 38px;
        }
      }
    }

    .add {
      position: absolute;
      right: 48px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 50%;
      background-color: #01EEFD;
      color: #fff;
      font-size: 22px;
      font-weight: blod;
      cursor: pointer;
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0rem;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    margin-top: 20px;
    background-color: #092B50;
    border: 4px #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: .2125rem;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: .025rem solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #0e75d4;
    font-size: 15px;

    span {
      cursor: pointer;
    }
  }

  .img {
    color: #fff;
    font-size: 24px;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: .5rem;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }

  /deep/.el-switch__label--left {
    position: relative;
    left: 70px;
    color: #fff;
    z-index: -1111;

    span {
      width: 40px;
    }
  }

  /deep/.el-switch__label--right {
    position: relative;
    right: 70px;
    color: #fff;
    z-index: -1111;

    span {
      display: inline-block;
      width: 40px;
    }
  }

  /deep/.el-switch__core {
    width: 65px !important;
  }

  /deep/.el-switch__label--left.is-active {
    z-index: 10;
    color: #fff;
    white-space: nowrap;
  }

  /deep/.el-switch__label--right.is-active {
    z-index: 10;
    white-space: nowrap;
  }
}
</style>
