<template>
  <div class="write">
    <div class="title">
      <span>编辑</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">用户名</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">性别</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">身份证号</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">手机号</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">平台密码</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">机构级别</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">手机号</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">机构编号</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">机构名称</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">下属机构</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">状态</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">责任医生</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="canvas">
          <span>签名</span>
          <div class="center">
            <canvas @mousemove="mouseMove" @mousedown="mouseDown" @mouseup="mouseUp" @mouseout="mouseOut"
              id="sign"></canvas>
            <div class="bottons">
              <el-button class="red" @click="clear">签名重写</el-button>
              <el-button @click="canvasToBase64">生成签名</el-button>
            </div>
          </div>
          <div class="upload">
            <el-button>选择签名上传</el-button>
            <span class="span">(只能上传jpg/png文件，且不超过500kb)</span>
          </div>
        </div>
      </div>
      <div class="btns">
        <el-button>取消</el-button>
        <el-button class="button">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      canvas: null,
      ctx: null,
      isDrawing: false,
      initX: null,
      initY: null,
      dataURL: ''
    }
  },
  mounted () {
    this.canvasSign()
  },
  methods: {
    canvasSign () {
      this.canvas = document.getElementById('sign')
      this.ctx = this.canvas.getContext('2d')
      this.canvas.width = 162
      this.canvas.height = 66
      this.ctx.lineWidth = 4
      this.ctx.strokeStyle = '#333'
      this.ctx.lineCap = 'round'
      this.ctx.lineJoin = 'round'
    },
    mouseDown (e) {
      this.isDrawing = true
      this.initX = e.offsetX
      this.initY = e.offsetY
    },
    mouseMove (e) {
      if (!this.isDrawing) return
      this.ctx.beginPath()
      // 起点
      this.ctx.moveTo(this.initX, this.initY)
      // 终点
      this.ctx.lineTo(e.offsetX, e.offsetY)
      this.ctx.stroke()
      this.initX = e.offsetX
      this.initY = e.offsetY
    },
    mouseUp () {
      this.isDrawing = false
    },
    mouseOut () {
      this.isDrawing = false
    },
    clear () {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      this.dataURL = ''
    },
    canvasToBase64 () {
      this.dataURL = this.canvas.toDataURL()
      // console.log(this.dataURL)
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 3.75rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 1);

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 700px;
    height: 6.825rem;
    margin-top: .125rem;
    padding: .625rem .25rem;
    padding-left: .9rem;
    background-image: url('../../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: .275rem;
      right: .25rem;
      color: #fff;
      font-size: .25rem;
      cursor: pointer;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }

    .item {
      display: flex;
      align-items: center;
      width: 33%;
      margin-bottom: .25rem;
      color: #01EEFD;
      font-size: .225rem;

      .span {
        width: 1rem;
        margin-right: .125rem;
      }

      .el-input {
        width: 1.875rem;
        height: .475rem;
        min-width: 120px;
      }
    }

    .canvas {
      display: flex;
      color: #01EEFD;
      font-size: .225rem;

      span {
        width: 1rem;
        margin-right: .125rem;
      }

      canvas {
        width: 2.075rem;
        height: .775rem;
        background-color: #fff;
      }

      .center {
        .bottons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 2.075rem;

          .el-button {
            width: 1rem;
            height: .325rem;
            line-height: .325rem;
            text-align: center;
            border: 0;
            border-radius: 0;
            padding: 0;
            background-color: #33465C;
            color: #fff;
            font-size: .175rem;
          }

          .red {
            background-color: #FF0000;
          }
        }
      }

      .upload {
        display: flex;
        flex-direction: column;
        margin-left: .2875rem;
        color: #01EEFD;
        font-size: .15rem;

        .el-button {
          width: 1.4375rem;
          height: .325rem;
          line-height: .325rem;
          padding: 0;
          border: 0;
          border-radius: 0;
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
          color: #fff;
          font-size: .175rem;
        }

        .span {
          width: 3.75rem;
          margin-top: .05rem;
        }
      }
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 3rem;
      height: .325rem;
      margin: .25rem auto 0;

      .el-button {
        width: .875rem;
        height: .325rem;
        line-height: .325rem;
        padding: 0;
        text-align: center;
        color: #fff;
        font-size: .175rem;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
