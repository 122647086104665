<template>
  <div class="write">
    <div class="title">
      <span>新增部门</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">父级部门</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">部门名称</span>
          <el-input placeholder="请输入部门名称"></el-input>
        </div>
        <div class="item">
          <span class="span">部门编号</span>
          <el-input placeholder="设置部门编号"></el-input>
        </div>
        <div class="item">
          <span class="span">部门类型</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">部门联系方式</span>
          <el-input></el-input>
        </div>
        <div class="item">
          <span class="span">部门地址</span>
          <el-input placeholder="请输入部门地址"></el-input>
        </div>
        <div class="item item1">
          <span class="span">部门备注</span>
          <el-input type="textarea">
          </el-input>
        </div>
        <div class="item">
          <span class="span">排序值</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="btns">
        <el-button>取消</el-button>
        <el-button class="button">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }],
      value: '',
      radio: '1'
    }
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 3.75rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 800px;
    height: 7.5rem;
    margin-top: .125rem;
    padding: .625rem .8125rem;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: .275rem;
      right: .3rem;
      color: #fff;
      font-size: .3rem;
      cursor: pointer;
    }

    .content-title {
      margin-top: .25rem;
      color: #01EEFD;
      font-size: .225rem;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }

    .item {
      display: flex;
      align-items: center;
      width: 33%;
      margin-top: .25rem;
      color: #01EEFD;
      font-size: .2rem;

      .span {
        margin-right: .125rem;
        white-space: nowrap;
      }

      .span1 {
        width: 1.35rem;
      }

      .el-input,
      .el-select {
        width: 2rem;
        height: .475rem;
      }

      ::v-deep .el-textarea__inner {
        resize: none;
      }
    }

    .item1 {
      width: 100%;
    }

    .btns {
      position: absolute;
      bottom: .7rem;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 3rem;
      height: .575rem;
      margin: 1.25rem auto 0;

      .el-button {
        height: .375rem;
        line-height: .375rem;
        padding: 0 .125rem;
        text-align: center;
        color: #fff;
        font-size: .175rem;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
