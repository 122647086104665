<template>
  <div class="list">
    <div class="info-title">
      <span>基本信息</span>
    </div>
    <el-form>
      <div class="info-box">
        <div class="item item1">
          <span>输入关键字</span>
          <el-input class="el-input-width" prefix-icon="el-icon-search" placeholder="请输入关键字"></el-input>
          <el-button placeholder="请输入关键字">清空</el-button>
        </div>
      </div>
    </el-form>
    <div class="add">
      <el-button>新增</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" height="380" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="42"></el-table-column>
        <el-table-column prop="name" label="编号"></el-table-column>
        <el-table-column label="省"></el-table-column>
        <el-table-column label="市"></el-table-column>
        <el-table-column label="县"></el-table-column>
        <el-table-column label="乡镇"></el-table-column>
        <el-table-column label="村"></el-table-column>
        <el-table-column label="机构编号"></el-table-column>
        <el-table-column label="机构名称"></el-table-column>
        <el-table-column label="机构级别"></el-table-column>
        <el-table-column label="档案数"></el-table-column>
        <el-table-column label="体检任务数"></el-table-column>
        <el-table-column label="操作" width="140">
          <!-- slot-scope="scope" -->
          <template>
            <div class="flex">
              <span @click="openUpdateList">编辑</span>
              <span>管辖用户</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
    <div class="btns">
      <el-button class="color">发布</el-button>
      <el-button>返回</el-button>
    </div>
    <!-- 编辑 -->
    <UpdateList v-if="showUpdateList" @close="closeUpdateList"></UpdateList>
  </div>
</template>

<script>
import UpdateList from './updateList.vue'
export default {
  components: {
    UpdateList
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: true
      }],
      showUpdateList: false
    }
  },
  methods: {
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openUpdateList () {
      this.showUpdateList = true
    },
    closeUpdateList () {
      this.showUpdateList = false
    }
  }
}
</script>

<style lang="less" scoped>
.list {
  position: relative;
  width: 100%;
  height: 100%;

  .info-title {
    width: 100%;
    height: 48px;
    line-height: 48px;
    padding-left: 18px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 20px;
    font-weight: bold;
  }

  .info-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px;
    padding-bottom: 0;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      width: 25%;
      margin-bottom: 20px;
      color: #01EEFD;
      font-size: 18px;

      span {
        margin-right: 10px;
      }

      .key {
        width: 80px;
        text-align-last: justify;
      }

      .el-input {
        width: 170px;
        height: 38px;
      }

      .el-input-width {
        width: 248px;
      }

      .el-button {
        width: 70px;
        height: 38px;
        line-height: 38px;
        margin: 0;
        margin-left: 20px;
        padding: 0;
        border: 0;
        border-radius: 0;
        color: #fff;
        font-size: 18px;
        background-color: #D2D2D2;
      }
    }

    .item1 {
      width: 50%;
    }
  }

  .add {
    display: flex;
    align-items: center;
    width: 100%;
    height: 46px;
    // padding-left: 30px;
    // box-sizing: border-box;

    .el-button {
      height: 26px;
      line-height: 26px;
      margin-right: 26px;
      padding: 0 21px;
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      border-radius: 0;
      border: 0;
      color: #fff;
      font-size: 14px;
    }
  }

  .table {
    width: 100%;
  }

  .el-table {
    border: 4px #073F73 solid;
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      // 整个滚动条
      width: 0px; // 纵向滚动条的宽度
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  /deep/.el-table__body tbody tr {
    color: #fff;
    font-size: 16px;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-table th.gutter {
    display: none;
    width: 0px;
  }

  /deep/.el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  /deep/ .el-checkbox__inner {
    background-color: transparent;
  }

  /deep/ .el-table__body-wrapper {
    background-color: #092B50;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;

    span {
      color: #0077F9;
      cursor: pointer;
    }

    .red {
      color: #FF0000;
    }

    //按钮文字显示
    .el-switch {
      height: 25px !important;
    }

    ::v-deep .el-switch__core {
      width: 54px !important;
      height: 24px;
      border-radius: 100px;
      border: none;
    }

    ::v-deep .el-switch__core::after {
      width: 20px;
      height: 20px;
      top: 2px;
    }

    ::v-deep .el-switch.is-checked .el-switch__core::after {
      margin-left: -21px;
    }

    /*关闭时文字位置设置*/
    ::v-deep .el-switch__label--right {
      position: absolute;
      z-index: 1;
      right: 6px;
      margin-left: 0px;
      color: rgba(255, 255, 255, 0.9019607843137255);

      span {
        font-size: 12px;
      }
    }

    /* 激活时另一个文字消失 */
    ::v-deep .el-switch__label.is-active {
      display: none;
    }

    /*开启时文字位置设置*/
    ::v-deep .el-switch__label--left {
      position: absolute;
      z-index: 1;
      left: 5px;
      margin-right: 0px;
      color: rgba(255, 255, 255, 0.9019607843137255);

      span {
        font-size: 12px;
      }
    }

  }

  .flex1 {
    justify-content: center;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 10px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }

  .btns {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding-left: 33px;
    box-sizing: border-box;

    .el-button {
      width: 70px;
      height: 26px;
      line-height: 26px;
      margin-right: 50px;
      text-align: center;
      background-color: #33465C;
      color: #fff;
      font-size: 14px;
      border: 0;
      padding: 0;
      border-radius: 0;
    }

    .color {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
