<template>
  <div class="mes">
    <div class="seeInfo-left">
      <div class="title">
        <span>部门列表</span>
      </div>
      <el-input class="margin" placeholder="机构名称" suffix-icon="el-icon-search">
      </el-input>
      <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
    </div>
    <div class="seeInfo-right">
      <div class="title">
        <span>我的部门</span>
      </div>
      <div class="switch">
        <span class="item" :class="{ item1: switchIndex === index }" v-for="(item, index) in switchs" :key="index"
          @click="switchClick(index)">{{ item }}</span>
      </div>
      <div class="box" v-if="(switchIndex === 0)">
        <div class="item">
          <span class="span">父级部门</span>
          <el-select class="width" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">部门名称</span>
          <el-input class="width" placeholder="请输入部门名称"></el-input>
        </div>
        <div class="item">
          <span class="span">部门编号</span>
          <el-input class="width" placeholder="请输入部门编号"></el-input>
        </div>
        <div class="item">
          <span class="span">部门类型</span>
          <el-select class="width" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">部门地址</span>
          <el-input class="width" placeholder="请输入"></el-input>
        </div>
        <div class="item item2">
          <span class="span">部门联系方式</span>
          <el-input class="width" placeholder="请输入"></el-input>
        </div>
        <div class="item item1">
          <span class="span">部门备注</span>
          <el-input type="textarea">
          </el-input>
        </div>
      </div>
      <div class="info" v-else-if="(switchIndex === 1)">
        <div class="info-title">
          <div class="info-box">
            <div class="left">
              <el-input class="el-input-width" placeholder="请输入菜单名称"></el-input>
              <el-button size="small" class="blue">搜索</el-button>
              <el-button size="small" class="gray">清空</el-button>
            </div>
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" height="340" :header-cell-style="{ background: '#024276' }">
            <el-table-column prop="name" label="人员名称"></el-table-column>
            <el-table-column label="人员头像"></el-table-column>
            <el-table-column label="人员性别"></el-table-column>
            <el-table-column label="人员类型"></el-table-column>
            <el-table-column label="职务等级"></el-table-column>
            <el-table-column label="联系方式"></el-table-column>
            <el-table-column label="操作">
              <!-- slot-scope="scope" -->
              <template>
                <div class="flex flex1">
                  <span @click="openDelete">删除</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
  </div>
</template>

<script>
import Delete from './delete.vue'
export default {
  components: {
    Delete
  },
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      data: [{
        label: '机构1',
        children: [{
          label: '临床门诊部'
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      tableData: [{
        date: '2020-02-27 17:20',
        name: '2020031400',
        type: '1',
        value: false
      }],
      switchs: Object.freeze(['基本信息', '用户信息']),
      switchIndex: 0,
      showDelete: false
    }
  },
  methods: {
    switchClick (index) {
      if (index === this.switchIndex) return
      this.switchIndex = index
    },
    handleNodeClick (data) {
      // console.log(data)
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    }
  }
}
</script>

<style lang="less" scoped>
.mes {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .seeInfo-left {
    width: 3.375rem;
    height: 10.3125rem;
    padding: .125rem;
    background-color: #081C38;
    box-sizing: border-box;

    .title {
      width: 100%;
      height: .4375rem;
      line-height: .52rem;
      text-align: center;
      background-image: url('../../img/ssjk/title.png');
      background-size: 100% 100%;
      color: #fff;
      font-size: .25rem;
      font-weight: bold;
    }

    .margin {
      margin: .125rem 0;
    }

    .el-select {
      margin-top: .25rem;
    }

    .btns {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin: .125rem auto;

      .el-button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        border-radius: 0;
        color: #fff;
        border: none;

        &::after {
          border: none;
        }
      }
    }

    .el-tree {
      background-color: #1D4070;
      color: #fff;

      .el-tree-node>.el-tree-node__content:hover {
        background-color: transparent;
      }

      .is-current {
        background-color: transparent;
        color: #01EEFD;
        background-color: #081C38;
      }

      .el-tree-node.is-current>.el-tree-node__content {
        background-color: transparent !important;
      }

      /deep/.el-tree-node>.el-tree-node__content:hover {
        background-color: transparent;
      }

      /deep/.is-current {
        background-color: transparent;
        color: #01EEFD;
        background-color: #081C38;
      }

      /deep/.el-tree-node.is-current>.el-tree-node__content {
        background-color: transparent !important;
      }
    }
  }

  .seeInfo-right {
    width: calc(100% - 3.625rem);
    height: 100%;
    padding: .125rem;
    border: .05rem solid rgba(1, 124, 219, .27);
    box-sizing: border-box;
    background-color: #08284B;

    .switch {
      display: flex;
      align-items: center;
      width: 100%;
      height: 1rem;
      margin: .25rem 0;
      padding-left: .3125rem;
      border-top: 1px solid #01EEFD;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;

      .item {
        height: .5rem;
        line-height: .5rem;
        margin-right: .25rem;
        padding: 0 .125rem;
        text-align: center;
        color: #fff;
        font-size: .175rem;
        background-color: rgba(115, 115, 115, .4);
        cursor: pointer;
      }

      .item1 {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }

    .title {
      width: 1.5rem;
      height: .4875rem;
      line-height: .4875rem;
      text-align: center;
      background-color: #01EEFD;
      color: #fff;
      font-size: .275rem;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      .item {
        display: flex;
        align-items: center;
        width: 24%;
        margin-bottom: .25rem;
        color: #01EEFD;
        font-size: .225rem;
      }

      .item1 {
        width: 100%;
      }

      .item2 {
        width: 74.7%;
      }

      .span {
        margin-right: .125rem;
        white-space: nowrap;
      }

      .span1 {
        margin-left: .125rem;
      }

      .width {
        width: 3.125rem;
      }

      .width1 {
        width: 1.5rem;
      }

      .el-textarea__inner {
        resize: none;
      }

    }

    .info {
      .info-title {
        width: 100%;
        height: .625rem;
        color: #01EEFD;
        font-size: .25rem;
        font-weight: bold;
      }

      .info-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 .125rem;
        padding-bottom: 0;
        box-sizing: border-box;

        .left {
          display: flex;
          align-items: center;

          .search-input {
            width: 3.25rem;
          }

          .el-select,
          .el-input {
            width: 2.5rem;
            margin-right: .125rem;
          }

          .date {
            margin-right: .125rem;
          }

          .blue,
          .gray {
            margin-left: .5rem;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            margin-right: .125rem;
            background-color: #284867;
          }
        }
      }

      .table {
        width: 99%;
        margin-top: .125rem;
        border: .05rem #073F73 solid;
      }

      /deep/.el-table__header {
        width: 100% !important;
      }

      ::v-deep .el-table__body-wrapper {
        &::-webkit-scrollbar {
          // 整个滚动条
          width: 0rem; // 纵向滚动条的宽度
        }
      }

      ::v-deep .el-table th.gutter {
        display: none;
        width: 0;
      }

      ::v-deep .el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      ::v-deep .el-table__body {
        width: 100% !important;
      }

      /deep/.el-table__header-wrapper {
        thead {
          th {
            div {
              color: #01EEFD;
              font-size: .2125rem;
              font-weight: 400;
            }
          }
        }
      }

      /deep/.el-table__body tbody tr {
        color: #fff;
        font-size: .2rem;
        background-color: #092B50;
      }

      /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background-color: #092B50;
      }

      /deep/.el-table tbody tr:hover>td {
        background-color: transparent !important
      }

      .el-table--border,
      .el-table--group {
        border: .025rem solid #ddd !important;
      }

      .el-table--border td,
      .el-table--border th,
      .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
        border-right: .0125rem solid #ddd !important;
      }

      /deep/.el-table td {
        border-bottom: .0125rem solid #01EEFD;
      }

      /deep/.el-table thead tr th {
        border-color: transparent;
      }

      /deep/ .el-table--border::after,
      /deep/ .el-table--group::after,
      /deep/ .el-table::before {
        background-color: #073F73;
      }

      /deep/.el-table--border {
        border: .0125rem solid #073F73 !important;
      }

      /deep/.el-table .cell {
        text-align: center;
        color: #01EEFD;
      }

      /deep/.el-table th.gutter {
        display: none;
        width: 0rem;
      }

      /deep/.el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      /deep/ .el-checkbox__inner {
        background-color: transparent;
      }

      /deep/ .el-table__body-wrapper {
        background-color: #092B50;
      }

      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: .2rem;

        span {
          color: #0077F9;
          cursor: pointer;
          letter-spacing: 0;
        }

        .red {
          color: #FF0000;
        }

        //按钮文字显示
        .el-switch {
          width: .875rem !important;
          height: .3125rem !important;
        }

        ::v-deep .el-switch__core {
          width: 100% !important;
          height: .3rem;
          border-radius: 1.25rem;
          border: none;
        }

        ::v-deep .el-switch__core::after {
          width: .25rem;
          height: .25rem;
          top: .025rem;
        }

        ::v-deep .el-switch.is-checked .el-switch__core::after {
          margin-left: -0.2625rem;
        }

        /*关闭时文字位置设置*/
        ::v-deep .el-switch__label--right {
          position: absolute;
          z-index: 1;
          right: .075rem;
          margin-left: 0rem;
          color: rgba(255, 255, 255, 0.9019607843137255);

          span {
            font-size: .15rem;
          }
        }

        /* 激活时另一个文字消失 */
        ::v-deep .el-switch__label.is-active {
          display: none;
        }

        /*开启时文字位置设置*/
        ::v-deep .el-switch__label--left {
          position: absolute;
          z-index: 1;
          left: .0625rem;
          margin-right: 0rem;
          color: rgba(255, 255, 255, 0.9019607843137255);

          span {
            font-size: .15rem;
          }
        }

      }

      .flex1 {
        justify-content: center;
      }

      .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: .125rem;

        ::v-deep.el-pagination__total,
        ::v-deep.el-pagination__jump {
          color: #01EEFD;

          .el-input__inner {
            color: #fff;
            background-color: transparent;
          }
        }

        ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #01EEFD;
        }
      }
    }
  }

}
</style>
