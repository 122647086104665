<template>
  <div class="write">
    <div class="title">
      <span>编辑职务</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">职务名称</span>
          <el-input></el-input>
        </div>
        <div class="item item1">
          <span class="span">职务编号</span>
          <el-input></el-input>
        </div>
      </div>
      <div class="btns">
        <el-button>取消</el-button>
        <el-button class="button">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '1',
      data: [{
        id: 1,
        label: '首页',
        children: [{
          id: 4,
          label: '首页统计数据图表1'
        },
        {
          id: 5,
          label: '首页统计数据图表2'
        }]
      }, {
        id: 2,
        label: '其他页面1',
        children: []
      }, {
        id: 3,
        label: '其他页面2',
        children: []
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 3.75rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 800px;
    height: 5rem;
    margin-top: .125rem;
    padding: .625rem .8125rem;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: .1625rem;
      right: .3rem;
      color: #fff;
      font-size: .3rem;
      cursor: pointer;
    }

    .content-title {
      margin-top: .25rem;
      color: #01EEFD;
      font-size: .225rem;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .item {
      display: flex;
      align-items: center;
      width: 33%;
      margin-top: .25rem;
      color: #01EEFD;
      font-size: .2rem;

      .span {
        margin-right: .125rem;
        white-space: nowrap;
      }

      .span1 {
        width: 1.35rem;
      }

      .el-input {
        width: 1.875rem;
        height: .475rem;
      }
    }

    .item1 {
      width: 66.5%;
    }

    .btns {
      position: absolute;
      bottom: .625rem;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 3rem;
      height: .575rem;
      margin: 0 auto;

      .el-button {
        height: .375rem;
        line-height: .375rem;
        padding: 0 .125rem;
        text-align: center;
        color: #fff;
        font-size: .175rem;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }

  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  .el-radio {
    color: #fff;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #01EEFD;
  }
}
</style>
