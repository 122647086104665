<template>
  <div class="referral">
    <div class="head">
      <el-button class="blue">报告管理</el-button>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="input">
      <div class="item">
        <span class="span">关键字</span>
        <el-input placeholder="请输入患者ID/姓名">
        </el-input>
      </div>
      <div class="item">
        <span class="span">检查日期</span>
        <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="item">
        <span class="span">设备类型</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="span">检查状态</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button class="blue width">搜索</el-button>
      <el-button class="blue width">重置</el-button>
    </div>
    <div class="tab">
      <div class="item" v-for="(item, index) in tabs" :key="index" :class="{ active: index === tabIndex }">{{ item }}</div>
    </div>
    <div class="swich">
      <el-table :data="tableData" height="456" :header-cell-style="{ background: '#024276' }">
        <el-table-column prop="name" label="患者ID">
        </el-table-column>
        <el-table-column prop="date" label="姓名">
        </el-table-column>
        <el-table-column prop="date" label="性别">
        </el-table-column>
        <el-table-column prop="type" label="年龄">
        </el-table-column>
        <el-table-column prop="type1" label="检查ID">
        </el-table-column>
        <el-table-column prop="type2" label="检查状态">
        </el-table-column>
        <el-table-column prop="type" label="设备状态">
        </el-table-column>
        <el-table-column prop="type" label="检查项目">
        </el-table-column>
        <el-table-column prop="type" label="检查类别">
        </el-table-column>
        <el-table-column prop="type" label="检查时间" sortable>
        </el-table-column>
        <el-table-column prop="type" label="打印时间" sortable>
        </el-table-column>
        <el-table-column prop="type" label="登记日期">
        </el-table-column>
        <el-table-column prop="type" label="归档状态">
        </el-table-column>
        <el-table-column label="操作" width="180">
          <!-- slot-scope="scope" -->
          <template >
            <div class="flex">
              <span>查看报告</span>
              <span>查看详情<span class="el-icon-caret-bottom"></span></span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '已结束'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      tabs: Object.freeze(['全部检查', '待检查', '待诊断', '诊断中', '已诊断']),
      tabIndex: 0,
      showInfo: false,
      showSeeReport: false,
      showSeeMaxImg: false,
      showOrderReview: false,
      showSeeDetails: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openInfo () {
      this.showInfo = true
    },
    closeInfo () {
      this.showInfo = false
    },
    openSeeReport () {
      this.showSeeReport = true
    },
    closeSeeReport () {
      this.showSeeReport = false
    },
    openSeeMaxImg () {
      this.showSeeMaxImg = true
    },
    closeSeeMaxImg () {
      this.showSeeMaxImg = false
    },
    openOrderReview () {
      this.showOrderReview = true
    },
    closeOrderReview () {
      this.showOrderReview = false
    },
    openSeeDetails () {
      this.showSeeDetails = true
    },
    closeSeeDetails () {
      this.showSeeDetails = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.referral {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  background: linear-gradient(114deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
  border: 4px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
    height: calc(100% - 250px);
  }

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .el-button {
      width: 152px;
      height: 38px;
      line-height: 38px;
      padding: 0;
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
    }

    .blue {
      margin-right: 20px;
      background-color: #01EEFD;
    }

    .color {
      margin-right: 20px;
      background-color: #D5D5D5;
    }

    .width {
      width: 80px;
      font-size: 18px;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;
    }

    /deep/.el-input__inner {
      background-color: transparent;
      color: #fff !important;
      font-size: 18px;
      border: none;

    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .el-button {
      width: 152px;
      height: 38px;
      line-height: 38px;
      padding: 0;
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
    }

    .blue {
      margin-right: 20px;
      background-color: #01EEFD;
    }

    .color {
      margin-right: 20px;
      background-color: #D5D5D5;
    }

    .width {
      width: 80px;
      font-size: 18px;
    }

    .item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      color: #01EEFD;
      font-size: 18px;

      .span {
        margin-right: 10px;
      }

      .el-input {
        width: 206px;
        height: 38px;
        line-height: 38px;
        background-color: #fff;

        /deep/.el-input__inner {
          height: 38px;
          line-height: 38px;
        }
      }
    }
  }

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;

    .item {
      margin: 0 28px;
      color: #FFFFFF;
      font-size: 22px;
      cursor: pointer;
    }

    .active {
      color: #04B4D0;
      border-bottom: 2px solid #04B4D0;
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    background-color: #092B50;
    border: 4px #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  // /deep/.el-table .sort-caret.ascending {
  //   border-bottom-color: #01EEFD;
  // }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    span {
      cursor: pointer;
    }
  }

  .flex1 {
    justify-content: center;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
