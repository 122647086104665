<template>
  <div class="referral">
    <div class="head">
      <el-button size="small" class="color" :class="{ blue: tabIndex === index }" v-for="(item, index) in tabs"
        :key="index" @click="tabClick(index)">{{ item }}</el-button>
    </div>
    <div class="content">
      <!-- 体检套餐 -->
      <Tab1 v-if="tabIndex === 0"></Tab1>
      <!-- 体检项目 -->
      <Tab2 v-if="tabIndex === 1"></Tab2>
      <!-- 套餐类型 -->
      <Tab3 v-if="tabIndex === 2"></Tab3>
    </div>

  </div>
</template>

<script>
import Tab1 from './tab1/tab1.vue'
import Tab2 from './tab2/tab2.vue'
import Tab3 from './tab3/tab3.vue'
export default {
  components: {
    Tab1,
    Tab2,
    Tab3
  },
  data () {
    return {
      tabs: Object.freeze(['体检套餐', '体检项目', '套餐类型']),
      tabIndex: 0,
      showSee: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.referral {
  position: relative;
  width: calc(100% - 10px);
  height: 100%;
  padding: 10px 37px;
  background: linear-gradient(114deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
  // border: 4px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
  }

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
    }

    .color {
      margin-right: 20px;
      background-color: #D5D5D5;
    }

    .blue {
      margin-right: 20px;
      background-color: #01EEFD;
    }

    .width {
      width: 80px;
      font-size: 18px;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: 18px;
        border: none;

      }
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 96px);
  }

}
</style>
