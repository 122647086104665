<template>
  <div class="write">
    <div class="title">
      <span>编辑</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">省</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">市</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">县</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">乡镇</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">村</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">机构编号</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">机构名称</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">机构级别</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span class="span">档案数</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
        <div class="item">
          <span>体检任务数</span>
          <el-input placeholder="请输入关键字"></el-input>
        </div>
      </div>
      <div class="btns">
        <el-button>取消</el-button>
        <el-button class="button">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 3.75rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 1);

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 700px;
    height: 6.825rem;
    margin-top: .125rem;
    padding: .625rem .25rem;
    padding-left: 1rem;
    background-image: url('../../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: .275rem;
      right: .25rem;
      color: #fff;
      font-size: .25rem;
      cursor: pointer;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
    }

    .item {
      display: flex;
      align-items: center;
      width: 33%;
      margin-bottom: .25rem;
      color: #01EEFD;
      font-size: .225rem;

      span {
        margin-right: .125rem;
      }

      .span {
        width: 1rem;
      }

      .el-input {
        width: 1.875rem;
        min-width: 120px;
        height: .475rem;
      }
    }

    .btns {
      position: absolute;
      left: 50%;
      bottom: .75rem;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 3rem;
      height: .325rem;

      .el-button {
        width: .875rem;
        height: .325rem;
        line-height: .325rem;
        padding: 0;
        text-align: center;
        color: #fff;
        font-size: .175rem;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
